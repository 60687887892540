// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmS1vwow6Ja27MSunpUmZSEY46uoqe9nw",
  authDomain: "autojedynka-8fe6f.firebaseapp.com",
  databaseURL:
    "https://autojedynka-8fe6f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "autojedynka-8fe6f",
  storageBucket: "autojedynka-8fe6f.appspot.com",
  messagingSenderId: "249569040051",
  appId: "1:249569040051:web:852963c574badfbd5242c6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
